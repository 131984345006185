import React from "react"
import Navbar from "../../Components/Navbar";
import ExperienceData from "./ExprienceData"
import PreviewBox from "../../Components/PreviewBox";
import Footer from "../../Components/Footer/Footer";

export default function Experience(){
    return <div>
        <Navbar/>
        <div className="Container">
            <div className="Content">
                <div className="Pre-title">Experience</div>
                {
						ExperienceData.map(data=>{
							return <PreviewBox title={data.title} subtext={data.subtext} link={data.link} description={data.description} image={data.image} type={data.type} />
						})
					}
            </div>
        </div>
        <Footer includeBackgroundColor={true}/>
    </div>
}