import React from "react"
import "./App.css"
import { createBrowserRouter, RouterProvider } from "react-router-dom"

import Home from "./Pages/Home/Home"
import Projects from "./Pages/Projects/Projects"
import About from "./Pages/About/About"
import Contact from "./Pages/Contact/Contact"
import Experience from "./Pages/Experience/Experience"
// import Project from "./Pages/Projects/Project"
import Redirector from "./Components/Redirector/Redirector"


const router = createBrowserRouter([
	// {
	// 	path: "/projects/:id",
	// 	element: (
	// 		<project />
	// 	),
	// },
	{
		path: "/home",
		element: <Home />,
	},
	{
		path: "/projects",
		element: <Projects />,
	},
	{
		path: "/experience",
		element: <Experience />,
	},
	{
		path: "/about",
		element: <About />,
	},
	{
		path: "/contact",
		element: <Contact />,
	},
	{
		path: "",
		element: <Home />,
	},
	{
		path: "*",
		element: <Redirector to="/home" />,
	},
])

function App() {

	return (
		<div className="App">
			<RouterProvider router={router} />
		</div>
	)
}

export default App
