import React, { useCallback } from "react"
import Particles from "react-particles"
import type { Engine } from "tsparticles-engine"
import { loadFull } from "tsparticles"
import { ISourceOptions } from "tsparticles-engine"

// import {Link} from "react-router-dom"
import Icons from "../../Icons/Icons"
import Footer from "../../Components/Footer/Footer"
import particlesOptions from "../../particles.json"

import "./Home.css"
// import "../../Alt.css";

export default function Home() {
	const particlesInit = useCallback(async (engine: Engine) => {
		await loadFull(engine)
	}, [])

	return (
		<div>
			{/* <div className="Container"> */}
			<Particles options={particlesOptions as ISourceOptions} init={particlesInit} />

			<div className="Content" style={{ alignItems: "center" }}>
				<div className="Pre-title">Hello</div>
				<div className="SmallText">Nice to meet you, I am</div>
				<div className="Title"> Edward Dan </div>
				<div className="Links">
					<a className="link noLinkStyle" href="/projects">
						Projects
					</a>
					<a className="link noLinkStyle" href="/experience">
						Experience
					</a>
					<a className="link noLinkStyle" href="/about">
						About me
					</a>
					<a className="link noLinkStyle" href="/contact">
						Contact
					</a>
				</div>
				<Icons />
			</div>
			<Footer />
		</div>

		// </div>
	)
}
