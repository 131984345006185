import React from "react"
import Navbar from "../../Components/Navbar"
import Footer from "../../Components/Footer/Footer"
import portrait from "../../Media/portrait.jpeg"

import "./About.css"

export default function About() {
	return (
		<div>
			<Navbar />
			<div className="Container">
				{/*<div className="Content" style={{alignItems:"center"}}>*/}
				{/*</div>*/}
				<div className="Content">
					<div className="Pre-title">About</div>
					<div className="about-flex">
						<img className="portrait" src={portrait} alt="portrait" />
						<div className="SmallText">
							<p>
								Hello! I'm Edward, a full-stack developer with a passion for working on data-powered projects and learning new
								tech! I love working with Discord bots, and data processing/analytics!
							</p>
							<p>I'm currently exploring the capabilities and applications of LLMs and Embeddings.</p>
							<p>I love listening to classic music, playing the piano, and watching anime! </p>
						</div>
					</div>
					<div className="Pre-title">Skills</div>

					<div className="Subtitle2">Programming Languages</div>
					<div className="SmallText">
                        TypeScript, Java, SQL, VBA, CSS, HTML, C++, C, Python
                    </div>

					<div className="Subtitle2">Tools</div>
					<div className="SmallText">
						Nodejs, Reactjs, React Native, Discord.js, Firebase Authentication, Google Sheets, Google Drive, Imgur,
						MongoDB, Segment, Mixpanel, Datadog, Github, Looker, BigQuery, Slack, Discord, Airflow, SSH, Docker, Google Analytics, AWS
					</div>

					<div className="Subtitle2">Software</div>
					<div className="SmallText">VSCode, Git, Figma, Photoshop, Final Cut Pro, Git, Audacity, Retool</div>

					<div className="Subtitle2">Languages</div>
					<div className="SmallText">
                        Native English & Mandarin
                    </div>
				</div>
			</div>
			<Footer includeBackgroundColor={true}/>
		</div>
	)
}
