import React, { useState, useEffect } from "react"

const email = [
	"e",
	"d",
	"a",
	"n",
	"@",
	"c",
	"o",
	"l",
	"l",
	"e",
	"g",
	"e",
	".",
	"h",
	"a",
	"r",
	"v",
	"a",
	"r",
	"d",
	".",
	"e",
	"d",
	"u",
]

export default function Email() {
	let [isRevealed, setReveal] = useState<boolean>()
	useEffect(() => {
		setReveal(false)
	}, [])
	if (!isRevealed) {
		return (
			<div className="SmallText" onClick={() => setReveal(true)}>
				Click to reveal Email
			</div>
		)
	} else {
		return (
			<a href={`mailto:${email.join("")}`}>
				<div className="SmallText">
					{email.map((c) => (
						<span>{c}</span>
					))}
				</div>
			</a>
		)
	}
}
