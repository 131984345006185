import React from "react";
import {redirect} from "react-router-dom"

interface RedirectorProps{
    to: string
}

export default function Redirector(props: RedirectorProps) {
    redirect(props.to)
    return <></>
}