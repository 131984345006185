import React from "react"
import "./Footer.css"

interface FooterProps {
	includeBackgroundColor?: boolean
}

export default function Footer(props: FooterProps) {
	return (
		<div
			className="Copyright"
			style={{
				backgroundColor: props.includeBackgroundColor ? "#efefef" : "transparent",
			}}
		>
			Copyright © 2023 Edward Dan
		</div>
	)
}
