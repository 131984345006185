import {PreviewBoxProps} from "../../Components/PreviewBox"
import blossomLogo from "../../Media/blossomSpinningIcon.gif"

const ExperienceData: Array<PreviewBoxProps> = [
    {
        title: "Work Experience",
        type: "category"
    },
    {
        title: "Capital One",
        subtext: "Data Engineer (Technical Intern Program) | May 2023 - Present",
        link: {
            url: "https://www.capitalone.com/",
            prefix: "external"
        },
        description: "Built an optimized C++ library for in-memory graph management & traversal, 2500x faster than the original proposed solution.",
        image: "https://i.imgur.com/Cc3Aa9t.png",
        type: "left"
    },
    {
        title: "Youtube",
        subtext: "Content Creator | Oct 2021 - Present",
        link: {
            url: "https://www.youtube.com/c/Niconiconii",
            prefix: "external"
        },
        description: "• Grew a programming youtube channel organically from 0 to 5,000 subscribers and 1M+ views in 6 months",
        image: "https://i.imgur.com/RhVM4dt.png",
        type: "left"
    },
    {
        title: "Snackpass",
        subtext: "Data Engineer | May 2022 - May 2023",
        link: {
            url: "https://www.snackpass.com/",
            prefix: "external"
        },
        description: "• Built automated data pipelines and ETL processes using Python, Airflow, Segment, RabbitMQ, and BigQuery\n" +
        "• Created a fuzzy-match database of 456k rows of web scraped data to generate sales leads\n" +
        "• Audited and debugged user onboard events in the react-native application",
        image: "https://i.imgur.com/TKgpOiv.png",
        type: "left"
    },
    {
        title: "Harvard College Consulting Group",
        subtext: "Case Team Leader | Sept 2021 — May 2022 | Cambridge, MA",
        link: {
            url: "https://www.harvardconsulting.org",
            prefix: "external"
        },
        description: "• Oversaw a six-person team's development of actionable recommendations to assist in product launch for a major gaming and messaging software company through 12 weeks of in-depth research as a case team lead\n" +
        "• Worked on a six-person team to recommend customer experience solutions through employee practices for a Fortune 500 data management company\n" +
        "• Conducted focus groups and interviews with customer survey data analysis to determine the most effective experience improvement strategies\n" +
        "• Provided a market entry strategy for a publicly traded consumer technology company's new product concept",
        image: "https://i.imgur.com/2npW2my.png",
        type: "left"
    },
    {
        title: "Freelance",
        subtext: "Discord Bot Developer | Nov 2019 — Dec 2021",
        description: "• Built a custom message counting bot for a Discord server with 40,000 members\n" +
                     "• Built a custom currency and voice-channel time-tracking bot for a 10,000 member partnered Discord server",
        image: "https://i.imgur.com/Yd6CkIU.png",
        type: "left"
    },
    {
        title: "Stealth Startup",
        subtext: "Product Manager | Feb 2021 — Jun 2021 | Remote",
        description: "• Conducted primary research through focus groups, interviews, and user surveys in online communities to identify consumer needs and gather feedback for application design",
        image: "https://i.imgur.com/zfYuHlU.png",
        type: "left"
    },
    {
        title: "Eureka Surveys",
        subtext: "Frontend Web Developer | Jul 2020 — Aug 2020 | Remote",
        link: {
            url: "https://eurekasurveys.com",
            prefix: "external"
        },
        description: "• Built React application with D3.js data visualizations for poll questionaires",
        image: "https://i.imgur.com/yJ8KGF3.png",
        type: "left"
    },
    {
        title: "Clemson University",
        subtext: "Research Assistant | Jul 2019 — Aug 2019 | Clemson, SC",
        description: "• Developed a machine learning model for identifying & scoring images of laser-sintered fuel cell samples",
        image: "http://www.clemson.edu/_images/home/TigerPaw_co.png",
        type: "left"
    },
    {
        title: "Volunteer Leadership",
        type: "category"
    },
    {
        title: "Music4Connections",
        subtext: "Founder/President | Apr 2016 — Present",
        link: {
            url: "https://fb.me/Music4Connections",
            prefix: "external"
        },
        description: "• Organized music concerts annually at local senior homes",
        image: "https://i.imgur.com/2dSMwDQ.jpg",
        type: "left"
    },
    {
        title: "Blossom Empire 113k Member Discord Server",
        subtext: "Online Community Manager/Owner | May 2020 — Present",
        link: {
            url: "https://discord.gg/join",
            prefix: "external"
        },
        description: "• Analyzed performance of member engagement and community growth\n" +
                     "• Developed gamification features to boost user engagement by 44%\n" + 
                     "• Managed a moderation team of 20 people, reducing community toxic language and behavior by 29%\n"+
                     "• Managed marketing campaigns and a 5-figure advertising budget",
        image: blossomLogo,
        type: "left"
    },
    {
        title: "Intoprogramming",
        subtext: "Founder/President | May 2018 - Jun 2018",
        link: {
            url: "https://bit.ly/intoprogramming",
            prefix: "external"
        },
        description: "• Taught Scratch and Java to middle school students",
        image: "https://i.imgur.com/5ICkNlW.png",
        type: "left"
    },
]
export default ExperienceData